import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Landing.module.css';
import './styles.css';
import CompanyNumbers from './CompanyNumbers_ar';

function HeroCarousel() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();
    let intervalId; // Define intervalId here

    const handleOurProductClick = () => {

        window.location.href = 'https://store.banknoteconsult.com/';
    }
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        const numPages = 3; // Number of pages in the carousel
        const intervalDuration = 6000; // Duration for each page display in milliseconds

        intervalId = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % numPages); // Cycle through pages
        }, intervalDuration);

        window.addEventListener('resize', handleResize);
        return () => {
            clearInterval(intervalId);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleSlideChange = (index) => {
        setActiveIndex(index);
    };
    return (
        <div id="carouselExampleFade" className="carousel" data-bs-ride="carousel" style={{ height: '500px' }}>
            <div className="carousel-inner">
                <div className={`carousel-item ${activeIndex === 0 ? 'active' : ''} `}>

                    <div style={{ flexDirection: 'row-reverse', marginRight: '.2rem' }} className="row justify-content-center ms-md-5 ml-2 ml-md-5 ms-auto">

                        <div className={`col-8 `} style={{ width: '60%', paddingTop: '4rem ' }}>
                            <div className='mb-3'>
                                <h1 className={styles.MainTitleWhite2}> نقدم أفضل</h1>
                                <h1 className={styles.MainTitleYellow2}> الحلول المالية</h1>
                            </div>
                            <div className='mb-3'>
                                <p className={styles.subParagraph2}> بنكنوت شركة استشارات مالية تقدم الخدمات المالية للمشاريع الكبيرة والصغيرة، لتحقيق أهدافها المالية</p>
                            </div>


                        </div>
                        <div className="col-4" style={{ width: '40%' }}>
                            <img src="/MobileCoin.png" alt="" style={{ height: '18rem', transform: 'scaleX(-1)' }} />                        </div>
                    </div>
                    <div style={{ marginLeft: '1.5rem' }}>
                        {windowWidth > 360 && windowWidth <= 1024 && (
                            <div className="company-numbers-wrapper ">
                                <CompanyNumbers animate={true} />
                            </div>
                        )}
                    </div>
                </div>
                <div className={`carousel-item ${activeIndex === 1 ? 'active' : ''}`}>
                    <div style={{ flexDirection: 'row-reverse', marginRight: '-.3rem' }} className="row justify-content-center ms-md-5 ml-2 ml-md-5 ms-auto">
                        <div className={`col-8 `} style={{ width: '60%' }}>
                            <div className='mb-3'>
                                <h1 className={styles.MainTitleWhite2}>تصدير المنتجات</h1>
                                <h1 className={styles.MainTitleYellow2}> للأسواق الخارجية</h1>
                            </div>
                            <div className='mb-3'>
                                <p className={styles.subParagraph2}>من الامتثال الجمركي وصولاً إلى إدارة اللوجستيات، بنكنوت تساعد مشروعك  ليزدهر في السوق العالمية</p>
                            </div>
                            <div className="mb-3" style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center' }} onClick={handleOurProductClick}>
                                <a href="" style={{ fontFamily: 'Cairo Light', textAlign: 'right' }} className='discover'>اكتشف منتجاتنتا   </a>
                                <svg style={{ transform: 'scaleX(-1)' }} xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 18 17" fill="none">
                                    <g clip-path="url(#clip0_1112_3956)">
                                        <path d="M9.74063 3.85938H4.17188L7.88438 8.5L4.17188 13.1406H9.74063L13.4531 8.5L9.74063 3.85938Z" stroke="#DDB96E" stroke-width="0.515625" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1112_3956">
                                            <rect width="16.5" height="16.5" fill="white" transform="translate(0.5625 0.25)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="col-4">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-5">
                                <div className={styles.SliderCont}>
                                    <div style={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }} className={styles.SliderTracker}>
                                        <div className={styles.SlideELe2}>
                                            <h1>مستلزمات طبية</h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>اخشاب</h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>معادن</h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>اطارات</h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>مواد غذائية</h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>طباعة</h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>اعلاف</h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>مستلزمات طبية </h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>اخشاب </h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>معادن </h1>
                                        </div>
                                        <div className={styles.SlideELe2}>
                                            <h1>اطارات </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ marginLeft: '1.5rem' }}>
                        {windowWidth > 360 && windowWidth <= 1024 && (
                            <div className="company-numbers-wrapper ">
                                <CompanyNumbers />
                            </div>
                        )}
                    </div>
                </div>

                <div className={`carousel-item ${activeIndex === 2 ? 'active' : ''}`}>
                    <div style={{ flexDirection: 'row-reverse', marginRight: '.2rem' }} className="row justify-content-center ms-md-5 ml-2 ml-md-5 ms-auto">

                        <div className={`col-8 `} style={{ width: '100%' }}>
                            <div className='mb-3'>
                                <h1 className={styles.MainTitleWhite2}  > استثمر نشاطك</h1>
                                <h1 className={styles.MainTitleYellow2}  > التجاري و اعرض</h1>
                                <h1 className={styles.MainTitleYellow2}  > منتجاتك معنا</h1>

                            </div>
                            <div className='mb-3'   >
                                <p className={styles.subParagraph2}> بنكنوت تساعدك في تنمية نشاطك التجاري من خلال الترويج لمنتجاتك  و تسهيل اجرائات التجارة و التصدير</p>
                            </div>
                        </div>

                    </div>
                    <div style={{ marginLeft: '1.5rem' }}>
                        {windowWidth > 360 && windowWidth <= 1024 && (
                            <div className="company-numbers-wrapper ">
                                <CompanyNumbers />
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="carousel-indicators ms-md-5 ml-md-5">
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="0" className={activeIndex === 0 ? "active" : ""} aria-current={activeIndex === 0 ? "true" : "false"} aria-label="Slide 1" onClick={() => handleSlideChange(0)}></button>
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="1" className={activeIndex === 1 ? "active" : ""} aria-current={activeIndex === 1 ? "true" : "false"} aria-label="Slide 2" onClick={() => handleSlideChange(1)}></button>
                <button type="button" data-bs-target="#carouselExampleFade" data-bs-slide-to="2" className={activeIndex === 2 ? "active" : ""} aria-current={activeIndex === 2 ? "true" : "false"} aria-label="Slide 3" onClick={() => handleSlideChange(2)}></button>
            </div>


            {windowWidth < 360 && windowWidth <= 1024 && (
                <div>
                    <CompanyNumbers />
                </div>
            )}
        </div>
    );
}

export default HeroCarousel;
