import React, { useState, useEffect } from 'react';
import Hero from '../Hero/Hero';
import Home from './Home';
import { Howl, Howler } from 'howler';


function Carousel() {
    const [activeIndex, setActiveIndex] = useState(0);

    // const handleNext = () => {
    //     setActiveIndex(1);
    // };

    // const handlePrev = () => {
    //     setActiveIndex(0);
    // };

    // const handleWheel = (event) => {
    //     if (event.deltaY > 0) {
    //         handleNext();
    //     } else {
    //         handlePrev();
    //     }
    // };
    useEffect(() => {
        // Initialize Howler
        Howler.volume(1); // Adjust volume (0.0 to 1.0)


        // Play the sound
        const sound = new Howl({
            src: './sound.mp3',
            autoplay: true,
            loop: false,
            volume: 1, // Adjust volume (0.0 to 1.0)

        });
        // Clear listener after first call.
        sound.once('load', function () {
            sound.play();
        });
        sound.play();
        // Clean up function
        return () => {
            sound.unload();
        };
    }, []); // Empty dependency array ensures the effect runs only once after the initial render

    useEffect(() => {
        // Add event listener for wheel scroll
        // window.addEventListener('wheel', handleWheel);

        // Auto-scroll to the second page after 5 seconds
        const autoScrollTimer = setTimeout(() => {
            setActiveIndex(1);
        }, 6000);

        // Remove the event listener and clear the timer when component unmounts
        return () => {
            // window.removeEventListener('wheel', handleWheel);
            clearTimeout(autoScrollTimer);
        };
    }, []);

    return (
        <div className='overlay'>
            {activeIndex === 0 && <Hero />}
            {activeIndex === 1 && <Home />}
        </div>
    );
}

export default Carousel;
