import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styles from './Partners.module.css';
import { useNavigate } from 'react-router-dom';

const fadeAnimationHandler = (props, state) => {
  const transitionTime = props.transitionTime + 'ms';
  const transitionTimingFunction = 'ease-in-out';

  let slideStyle = {
    position: 'absolute',
    display: 'block',
    minHeight: '100%',
    opacity: 0,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    transitionTimingFunction: transitionTimingFunction,
    msTransitionTimingFunction: transitionTimingFunction,
    MozTransitionTimingFunction: transitionTimingFunction,
    WebkitTransitionTimingFunction: transitionTimingFunction,
    OTransitionTimingFunction: transitionTimingFunction,
  };

  if (!state.swiping) {
    slideStyle = {
      ...slideStyle,
      WebkitTransitionDuration: transitionTime,
      MozTransitionDuration: transitionTime,
      OTransitionDuration: transitionTime,
      transitionDuration: transitionTime,
      msTransitionDuration: transitionTime,
    };
  }

  return {
    slideStyle,
    selectedStyle: {
      ...slideStyle,
      opacity: 1,
      position: 'relative',
    },
    prevStyle: {
      ...slideStyle,
      opacity: 0, // Fade out the previous slide
    },
  };
};

const Partners = () => {
  const navigate = useNavigate();
  // Define your handleButtonClick function
  const handleButtonClick = async (buttonNumber) => {
    try {
      // Find the partner data based on the buttonNumber
      const partner = data[buttonNumber - 1];

      if (partner) {


        navigate(`/partnersDetails?id=${partner.partnerName}`, {
          state: {
            partnerName: partner.partnerName,
            LogoimageSrc: partner.LogoimageSrc,
            image1Src: partner.image1Src,
            image2Src: partner.image2Src,
            videoSrc: partner.videoSrc,
            videoThumbnail: partner.videoThumbnail,
            info: partner.info
          }
        });
      } else {
        console.error(`Partner not found for button number ${buttonNumber}`);
      }
    } catch (error) {
      console.error(error);
      // Handle the error as needed
    }
  };

  const sectors = [
    'Page1',
    'Page2',

  ];


  const data = [
    {
      partnerName: 'Toy & Joy',
      buttonNumber: 1,
      LogoimageSrc: '/Partners/Toy & Joy/logo.png',
      image1Src: '/Partners/Toy & Joy/1.jpg',
      image2Src: '/Partners/Toy & Joy/2.jpg',
      videoSrc: '/Partners/Toy & Joy/video.mp4',
      videoThumbnail: '/Partners/Toy & Joy/videoThumbnail.jpg',
      info: 'تاسست شركه توي اند جوي عام ٢٠١١ و هي الشركه المتخصصه في بيع لعب الأطفال كما انه متخصص بجميع أفكار المواد التعليمية مثل الألعاب بالأرقام و الحروف لتعليم الأطفال لدي الشركه العديد من الفروع'
    },
    {
      partnerName: '2M Group',
      buttonNumber: 2,
      LogoimageSrc: '/Partners/2M Group/logo.png',
      image1Src: '/Partners/2M Group/1.jpg',
      image2Src: '/Partners/2M Group/2.jpg',
      videoSrc: '/Partners/2M Group/video.mp4',
      videoThumbnail: '/Partners/2M Group/videoThumbnail.jpg',
      info: 'تاسست شركه تو ام لخدمات الطباعه عام ٢٠١١ و هي شركه تعمل في خدمات الطباعه و مستلزماتها لديها العديد من العملاء مثل شركه ارت ميديا و ميديا برنت'
    },
    {
      partnerName: 'Al Eman',
      buttonNumber: 3,
      LogoimageSrc: '/Partners/Al Eman/logo.png',
      image1Src: '/Partners/Al Eman/1.jpg',
      image2Src: '/Partners/Al Eman/2.jpg',
      videoSrc: '/Partners/Al Eman/video.mp4',
      videoThumbnail: '/Partners/Al Eman/videoThumbnail.jpg',
      info: 'تم تأسيس مصنع الايمان عام 2017. يعد مصنع الايمان للبلاستيك من أكبر رواد مصانع البلاستيك في نفخ البلاستيك/ براميل/الجراكن/احدث انواع الكراسي والترابيزات بجميع الأحجام والأشكال والثقافات والاوزان والألوان والمواصفات العالمية التي تخدم جميع الصناعات.'
    },
    {
      partnerName: 'Merco Market',
      buttonNumber: 4,
      LogoimageSrc: '/Partners/Merco Market/logo.png',
      image1Src: '/Partners/Al Hosam/1.jpg',
      image2Src: '/Partners/Al Hosam/2.jpg',
      videoSrc: '/Partners/Al Hosam/video.mp4',
      videoThumbnail: '/Partners/Al Hosam/videoThumbnail.jpg',
      info: 'تأسست شركة ميركو ماركت عام ٢٠١٦ وهي تعمل في نشاط تصنيع إضافات الأعلاف والأسمدة العضوية. من أهم عملائها شركة المدينة للتبادل التجاري وشركة فينوس. الشركة حاصلة على كافة التراخيص اللازمة.'
    },
    {
      partnerName: 'Al Masrya Foam',
      buttonNumber: 5,
      LogoimageSrc: '/Partners/Al Masrya Foam/logo.png',
      image1Src: '/Partners/Al Masrya Foam/1.jpg',
      image2Src: '/Partners/Al Masrya Foam/2.jpg',
      videoSrc: '/Partners/Al Masrya Foam/video.mp4',
      videoThumbnail: '/Partners/Al Masrya Foam/videoThumbnail.jpg',
      info: 'تأسست شركه المصريه فوم عام ٢٠١٦ بداءت في صناعه و توزيع منتجات الأسفنج الصناعي بكافه الكثافات المختلفه ، قامت الشركه بالاستعانة بالعديد من الفنيين للعمل علي انتاج مراتب ذو جوده عاليه و تم العمل علي إنشاء ماركه اخري تحت راعيه المصريه فوم تم إطلاق اسم (كلاود بيد) و هي نوع من المراتب ذات الجوده العاليه بجانب ان المصنع يمتلك العديد من الالات و المعدات عاليه الجوده لإنتاج افضل الخامات.'
    },
    {
      partnerName: 'Golden Power',
      buttonNumber: 6,
      LogoimageSrc: '/Partners/Golden Power/logo.png',
      image1Src: '/Partners/Al Mostafa/1.jpg',
      image2Src: '/Partners/Al Mostafa/2.jpg',
      videoSrc: '/Partners/Al Mostafa/video.mp4',
      videoThumbnail: '/Partners/Al Mostafa/videoThumbnail.jpg',
      info: 'تأسست شركة جولدن باور عام 2015، وهي تعمل في توزيع وتجارة الزيوت والشحوم. لديها عدة فروع في محافظات مصر، وتمتلك أسطولًا من السيارات لتوصيل وتوريد منتجاتها. حاليًا، تقوم الشركة بالتوسع وبناء مصنع لتعبئة زيوت السيارات'
    },
    {
      partnerName: 'Maka',
      buttonNumber: 7,
      LogoimageSrc: '/Partners/Maka/logo.png',
      image1Src: '/Partners/Al Shorouq/1.jpg',
      image2Src: '/Partners/Al Shorouq/2.jpg',
      videoSrc: '/Partners/Al Shorouq/video.mp4',
      videoThumbnail: '/Partners/Al Shorouq/videoThumbnail.jpg',
      info: 'تاسست شركه مكه فود عام ٢٠١٩ و هي تعمل في مجال بيع اللحوم المجمده، تقوم الشركه ببيع و توزيع منتجاتها لشركات عالميه مثل شركه القاهره لمجازر الدواجن (كوكي) كما انها تتعامل ايضاً مع عده شركات اخري مثل سيلفا للمواد الغذائيه'
    },
    {
      partnerName: 'Happy Stars',
      buttonNumber: 8,
      LogoimageSrc: '/Partners/Happy Stars/logo.png',
      image1Src: '/Partners/Al Sonosy/1.jpg',
      image2Src: '/Partners/Al Sonosy/2.jpg',
      videoSrc: '/Partners/Al Sonosy/video.mp4',
      videoThumbnail: '/Partners/Al Sonosy/videoThumbnail.jpg',
      info: 'تأسست شركة هابي ستارز عام 2007، وتعمل في تجارة وتوزيع المواد الغذائية. تخدم الشركة عددًا من العملاء على مستوى محافظات مصر نظرًا للطلب الدائم على المواد الغذائية. كما تقوم الشركة بخدمة الأسواق بالوجه القبلي في محافظات مصر وتمتلك عددًا من السيارات لخدمة نشاطها.'
    },
    {
      partnerName: 'Arafa Truck',
      buttonNumber: 9,
      LogoimageSrc: '/Partners/Arafa Truck/logo.png',
      image1Src: '/Partners/Arafa Truck/1.jpg',
      image2Src: '/Partners/Arafa Truck/2.jpg',
      videoSrc: '/Partners/Arafa Truck/video.mp4',
      videoThumbnail: '/Partners/Arafa Truck/videoThumbnail.jpg',
      info: 'تأسست شركة عرفة تراك عام 2009، حيث تقدم المجموعة حلولًا مخصصة لكافة أعمال اللوجستيات. الشركة تعمل في تأجير وبيع مجموعة واسعة من المعدات، بالإضافة إلى تقديم خدمات صيانة إضافية وخدمات الموظفين لمساعدة شركات المقاولات والبناء في أداء أعمالها.'
    },
    {
      partnerName: 'Food Service',
      buttonNumber: 10,
      LogoimageSrc: '/Partners/Food Service/logo.png',
      image1Src: '/Partners/Food Service/1.jpg',
      image2Src: '/Partners/Food Service/2.jpg',
      videoSrc: '/Partners/Food Service/video.mp4',
      videoThumbnail: '/Partners/Food Service/videoThumbnail.jpg',
      info: 'تأسست شركة فود سيرفيس عام 2014، وتعمل في إنتاج وتوزيع المواد الغذائية. لديها العديد من خطوط الإنتاج وتمتلك أسطولًا من السيارات لتوزيع منتجاتها. من منتجاتها يشمل الخل الأبيض، التفاح، دبس الرمان، ماء الورد، حمض الليمون، أرز البسمتي، الشوفان، والعديد من المنتجات الأخرى.'
    },
    {
      partnerName: 'Neslo',
      buttonNumber: 11,
      LogoimageSrc: '/Partners/Neslo/logo.png',
      image1Src: '/Partners/Hesham Tyres/1.jpg',
      image2Src: '/Partners/Hesham Tyres/2.jpg',
      videoSrc: '/Partners/Hesham Tyres/video.mp4',
      videoThumbnail: '/Partners/Hesham Tyres/videoThumbnail.jpg',
      info: 'تاسست شركه نسلو عام ٢٠٢٠ و هي تعمل في نشاط تعبئه و تغليف المواد الغذائيه المختلفه كما ان الشركه لديها شهاده العضويه لغرفه الصناعات الغذائيه و لديها العديد من السيارات لخدمه النشاط علي مستوي محافظات مصر'
    },
    {
      partnerName: 'Heslo',
      buttonNumber: 12,
      LogoimageSrc: '/Partners/Heslo/logo.png',
      image1Src: '/Partners/Heslo/1.jpg',
      image2Src: '/Partners/Heslo/2.jpg',
      videoSrc: '/Partners/Heslo/video.mp4',
      videoThumbnail: '/Partners/Heslo/videoThumbnail.jpg',
      info: 'تأسست شركة هيسلو بلايندز عام 1995. تمتلك الشركة العديد من الفروع بجانب المصنع وتعمل في العديد من الأنواع المختلفة من أتمتة المنزل التي تعرف باسم المنزل الذكي. تصنع الشركة مجموعة كبيرة من الستائر الآلية، والمسارح، ومصاريع الأسطوانة، والمنور، والخيام، وأبواب المرآب، والبوابات من التصاميم الحديثة والكلاسيكية والألوان العصرية. تجعل هيسلو التحكم بسيطًا وتقدم خطوطها تجربة مطلقة في أتمتة المنزل بالكامل.'
    },
    {
      partnerName: 'International Textile',
      buttonNumber: 13,
      LogoimageSrc: '/Partners/International Textile/logo.png',
      image1Src: '/Partners/International Textile/1.jpg',
      image2Src: '/Partners/International Textile/2.jpg',
      videoSrc: '/Partners/International Textile/video.mp4',
      videoThumbnail: '/Partners/International Textile/videoThumbnail.jpg',
      info: 'شركة انترناشونال تكستيل تأسست عام ٢٠٠٨. تمتلك الشركة خطوط إنتاج كاملة مثل تصنيع الغزل والنسيج والصباغة والطباعة والتجهيز. الشركة تتميز بصناعة أجود الخامات البوليسترية وتعد رائدة في مجال صناعة الغزل والنسيج في مصر. كما تتخصص الشركة في صناعة أجود أقمشة القطن والبوليستر والأقمشة المخلوطة، وتتميز أيضًا بخبرتها في الصباغة والطباعة والتجهيز، وتقدم طباعة عالية الجودة تتماشى مع أحدث صيحات الموضة.'
    },
    {
      partnerName: 'Metal Tech',
      buttonNumber: 14,
      LogoimageSrc: '/Partners/Metal tech/logo.png',
      image1Src: '/Partners/Metal tech/1.jpg',
      image2Src: '/Partners/Metal tech/2.jpg',
      videoSrc: '/Partners/Metal tech/video.mp4',
      videoThumbnail: '/Partners/Metal tech/videoThumbnail.jpg',
      info: 'تأسست شركه ميتال تك عام ٢٠١٨ و هي تعمل في تصنيع و تجاره و توريد المهمات الكهربائيه و اللوحات الكهربائيه و مكوناتها و حوامل الكابلات بجميع أنواعها لدي الشركه المصنع الخاص بها مع امكانيه تصميم كافه اللوحات الكهربائيه التي يحتاج لها المستهلك'
    },
    {
      partnerName: 'Porsh',
      buttonNumber: 15,
      LogoimageSrc: '/Partners/porsh/logo.png',
      image1Src: '/Partners/Porsh/1.jpg',
      image2Src: '/Partners/Porsh/2.jpg',
      videoSrc: '/Partners/Porsh/video.mp4',
      videoThumbnail: '/Partners/Porsh/videoThumbnail.jpg',
      info: 'تأسست شركه بورش للتكنولوجيا عام ١٩٩٧ و هي تعمل في جميع وبيع أجهزة الكمبيوتر الشخصية التي تحمل اسمها GME PC، توزيع مكونات الكمبيوتر، تصميم وتطبيق وصيانة شبكات الحاسب الآلي، كما ان الشركه تعمل مع اكبر شركات لخدمات الكمبيوتر مثل راديو شاك، كمبيوتر شوب، بي تك.'
    },
    {
      partnerName: 'Power Pack',
      buttonNumber: 16,
      LogoimageSrc: '/Partners/Power Pack/logo.png',
      image1Src: '/Partners/Power Pack/1.jpg',
      image2Src: '/Partners/Power Pack/2.jpg',
      videoSrc: '/Partners/Power Pack/video.mp4',
      videoThumbnail: '/Partners/Power Pack/videoThumbnail.jpg',
      info: 'تم تأسيس مصنع باور باك عام 2019. يمتلك المصنع 7 خطوط إنتاج. يتميز المنتج بالجودة العالية: مصنوعة من القطن والبوليستر والكتان، مع خياطة مدمجة في كل مكان، هذه الشنطة متينة للغاية للاستخدام اليومي. سعة كبيرة والتي يمكن أن تلبي بسهولة احتياجاتك اليومية.'

    },
    {
      partnerName: 'Royal Palace',
      buttonNumber: 17,
      LogoimageSrc: '/Partners/Royal Palace/logo.png',
      image1Src: '/Partners/Royal Palace/1.jpg',
      image2Src: '/Partners/Royal Palace/2.jpg',
      videoSrc: '/Partners/Royal Palace/video.mp4',
      videoThumbnail: '/Partners/Royal Palace/videoThumbnail.jpg',
      info: 'تأسست شركه القصر الملكي عام ٢٠٢١ كشركه رائده في مجال صناعه الأثاث المنزلي بكافه اشكاله تأسس ذلك المصنع علي مساحه ٢٥ الف متر مربع بخلاف المخازن الكبري الخارجيه يمتلك المصنع العديد من الالات لصناعه كافه أنواع المفروشات للمعارض و المكاتب و كافه مستلزمات المنازل.'
    },
    {
      partnerName: 'Al Sama',
      buttonNumber: 18,
      LogoimageSrc: '/Partners/Sama/logo.png',
      image1Src: '/Partners/Al Sama/1.jpg',
      image2Src: '/Partners/Al Sama/2.jpg',
      videoSrc: '/Partners/Al Sama/video.mp4',
      videoThumbnail: '/Partners/Al Sama/videoThumbnail.jpg',
      info: 'تأسس مصنع السما للبلاستيك عام 2011. وهو متخصص في تصنيع الأسطوانات والأكياس من البولي إيثيلين. نعمل أيضًا في صناعة الأكياس القابلة للتحلل ونتعامل مع شركات فرنسية تعمل في مجال التنقيب عن النفط. حصلنا على شهادة من مركز تكنولوجيا البلاستيك بعد إجراء الاختبارات اللازمة للمنتج والتأكد من قابليته للتحلل تحت تأثير العوامل الجوية. تمكنا من بناء سمعتنا في هذا المجال والتعامل مع كبرى الشركات في قطاعات الورق والمعادن والبلاستيك.'
    },

    {
      partnerName: 'Tyre Market',
      buttonNumber: 19,
      LogoimageSrc: '/Partners/Tyre Market/logo.png',
      image1Src: '/Partners/Tyre Market/1.jpg',
      image2Src: '/Partners/Tyre Market/2.jpg',
      videoSrc: '/Partners/Tyre Market/video.mp4',
      videoThumbnail: '/Partners/Tyre Market/videoThumbnail.jpg',
      info: 'تأسست شركة تاير ماركت عام 2015، وهي تعمل في مجال بيع الإطارات والبطاريات للسيارات من خلال عدة فروع في القاهرة الكبرى. تمتلك الشركة عددًا من مراكز الخدمات، وتعد من بين أهم عملائها المكتب العربي للمقاولات، شركة بناء، وشركة الفتح للمقاولات.'
    },
    {
      partnerName: 'Alghandour',
      buttonNumber: 20,
      LogoimageSrc: '/Partners/Bon Alghandour/logo.png',
      image1Src: '/Partners/Bon Alghandour/1.jpg',
      image2Src: '/Partners/Bon Alghandour/2.jpg',
      videoSrc: '/Partners/Bon Alghandour/video.mp4',
      videoThumbnail: '/Partners/Bon Alghandour/videoThumbnail.jpg',
      info: 'تأسست مؤسسة بن الغندور عام 2004، وتعمل في مجال طحن وتعبئة وتغليف البن، بالإضافة إلى تقديم المشروبات الساخنة من خلال عدة فروع. تخدم المؤسسة عددًا من العملاء مثل الشركات والمؤسسات من خلال توريد البن لهؤلاء المنشآت.'
    },
    { partnerName: 'Mansour', buttonNumber: 21, imageSrc: '/image5.png', info: 'تأسست شركه منصور للمقاولات عام ٢٠٢١ و هي شركة هندسة إنشاءات، لديها فريق ديناميكي وواسع المعرفة ومتحمس، ولديها خبرة واسعة في إدارة المشاريع الصناعية على المستوى الدولي،كما انها تغطي مجموعة واسعة من الخدمات التي تشمل: -التصميم والبناء (مشاريع تسليم المفتاح) -مشروع / إدارة البناء -إدارة سلامة البناء -تدقيق إدارة السلامة والبناء', LogoimageSrc: '/Partners/Mansour/logo.png' },
    { partnerName: 'El Monsef', buttonNumber: 22, imageSrc: '/image5.png', info: 'تاسست شركه المنصف عام ٢٠١٩ و هي تعمل في مجال تصنيع الملابس الجاهزه كما ان الشركه لديها العديد من العقود مع الشركات الكبري مثل شركه تيما للتصنيع كما انها تصنع لدي الغير بالتعاون مع شركه الوان و هي احدي الشركات التابعه للمجموعه LC Waikiki', LogoimageSrc: '/Partners/El Monsef/logo.png' },
    { partnerName: 'MG', buttonNumber: 23, imageSrc: '/image5.png', info: 'تأسست شركة إم جي فارما عام 2014، وهي تعمل في مجال تصنيع الأدوية ومستحضرات التجميل للأغراض الخارجية. الشركة لديها عقود مع عدة شركات لتصنيع منتجاتها، مثل أميتا لمستحضرات التجميل، وفارما زاد، وفارما جين. كما تقوم الشركة بتوزيع منتجاتها لأبرز الشركات مثل فارما أوفر سيز وشركة ابن سينا.', LogoimageSrc: '/Partners/MG/logo.png' },
    { partnerName: 'Urgent', buttonNumber: 24, imageSrc: '/image5.png', info: 'تاسست شركه ارجنت ايجيبت للنقل و الشحن عام ١٩٩٢ و هي تعمل في مجال نقل البضائع و الطرود عبر مركبات و وسائل نقل مختلفه و تقدم الشركه خدماتها للكيانات الكبري وذلك من خلال عده فروع و سيارات الخدمه لنقل جميع البضائع بين كافه محافظات الجمهوريه', LogoimageSrc: '/Partners/Urgent/logo.png' },
    { partnerName: 'Master', buttonNumber: 25, imageSrc: '/image5.png', info: 'تاسست شركه ماستر للمقاولات عام ٢٠١٧ و متخصصه في القيام بأعمال المقاولات العموميه و تجاره مواد البناء ، الشركه تمتلك العديد من المقرات الاداريه لخدمه المحافظات المصريه، كما ان الشركه قامت بتنفيذ إنشاء و تشطيب عده مشاريع بالعاصمه الاداريه الجديده و ايضاً مشاريع بعده محافظات مثل محافظه الشرقيه و قنا', LogoimageSrc: '/Partners/Master/logo.png' },
    {
      partnerName: 'Al Ekhwa',
      buttonNumber: 26,
      imageSrc: '/image5.png',
      info: 'تأسست شركة الأخوة عام 2014، وتملك المصنع 6 فروع متخصصة في جميع أنواع الرولات والأكياس المطبوعة حتى 6 ألوان بطباعة ديجيتال. تقدم الشركة جميع أنواع أكياس البلاستيك والرولات الجاهزة والمطبوعة. تعتبر الشركة من الشركات الرائدة في صناعة رولات البلاستيك والأكياس، سواء في صناعة أكياس الخدمة الشاقة لتعبئة الأسمدة أو إنتاج أكياس الفيلم الخفيف. تقوم الشركة أيضًا بالتصدير إلى دول الاتحاد الأوروبي وأفريقيا ولبنان.',
      LogoimageSrc: '/Partners/Al Akhwa/logo.png'
    }
    , { partnerName: 'El Manahry', buttonNumber: 27, imageSrc: '/image5.png', info: 'تاسست شركه المناهري عام ٢٠١٨ و هي تعمل في مجال تصنيع الحصير البلاستيك لديها العديد من العملاء داخل و خارج الجمهوريه، حاصله علي جميع معايير الجوده و شهاده عضويه باتحاد الصناعات المصريه ، تتعامل شركه المناهري مع شركه سابك لتصنيع الخامات البلاستيكيه و ايضاً مصنع الراعي للبلاستيك', LogoimageSrc: '/Partners/Al Manahry/logo.png' },
    {
      partnerName: 'First',
      buttonNumber: 28,
      imageSrc: '/image5.png',
      info: 'تأسست شركة فرست عام 2016، واكتسبت الأفضلية والتمييز بين الشركات بفضل نزاهتنا في صناعة البلاستيك وثقة العملاء في المصداقية. نشارك في السوق ونشارك في مناقصات ونعقد تعاقدات مع شركات كبرى. يتميز منتجنا بالجودة والأمان، ونعمل على تحسين جودة جميع أعمالنا بنظام جودة وأمان ممتاز يواكب التحديات المستمرة ويتوافق مع متطلبات العملاء. أبرز إجراءاتنا في مجال الجودة تشمل الفحص المستمر والمراقبة والصيانة الدورية لمختلف مراحل التصنيع، مع متابعة أداء كافة الموظفين بالشركة وقياس رضا عملائنا عن الخدمات التي نقدمها، واتخاذ كافة الاحتياطات اللازمة للسلامة والأمان.',
      LogoimageSrc: '/Partners/First/logo.png'
    }
    ,
    { partnerName: 'Ram', buttonNumber: 29, imageSrc: '/image5.png', info: 'تاسست شركة رام لصناعة الأعلاف عام 2010 وهي شركة تعمل في مجال أغذية الحيوانات الأليفة. منذ بداية التأسيس، تم تعاقد الشركة مع شركة ميرا الألمانية باعتبارها الوكيل الحصري لجميع منتجاتها في مصر والشرق الأوسط. ثم توالت التعاقدات مع عدد من العلامات التجارية المتميزة في مختلف مجالات أعلاف الحيوانات الأليفة. في عام 2016، اتجهت شركة رام نحو التصنيع لتحقيق طفرة في مجال تصنيع وإنتاج الأعلاف المحلية باستخدام أجود وأفضل أنواع المواد والمكونات المناسبة التي تضمن للمربي الرعاية المتميزة للحيوان الأليف الذي يمتلكه.', LogoimageSrc: '/Partners/Ram/logo.png' },
    { partnerName: 'Future Transport', LogoimageSrc: '/Partners/Future/logo.png', buttonNumber: 30, imageSrc: '/image5.png', info: 'تاسست شركه فيوتشر ترانسبورت عام ٢٠١٣ تقوم الشركه بتقديم خدمات نقل العاملين بالشركات و ايجار جميع أنواع السيارات لنقل الموظفين كما ان الشركه تمتلك أسطول من السيارات لخدمه نشاطه ومن اهم عملائها شركه ليبتس ايجيبت للادويه و المستحضرات الطبيه، GIG مصر، ايديتا للصناعات الغذائيه' },
    { partnerName: 'Samo', buttonNumber: 31, imageSrc: '/toyjoy.svg', info: 'تاسست شركه سامو عام ٢٠٠٦ و هي تعمل في مجال انتاج الملابس الجاهزه بأنوعها و ايضاً صناعه المفروشات المنزليه و الاغطيه بكافه أنواعها و انتاج خيوط الحياكه و التطريز، تتميز شركه سامو بتصميمتها الخاصه', LogoimageSrc: '/Partners/Samo/logo.png' },
    { partnerName: 'Holandia', buttonNumber: 32, imageSrc: '/YBG.svg', info: 'تم تأسيس شركة الهولندية وايت جولد عام 2019، وهي شركة رائدة في صناعة خامات الحلويات والشوكولاتة، ومساحيق العصائر والبودرة ميكس، وصناعة الجلوتين الحيوي، بالإضافة إلى العديد من المنتجات الأخرى. الشركة تخدم عددًا من العملاء مثل شركة التوفيق والمدينة المنورة للخامات.', LogoimageSrc: '/Partners/Holandia/logo.png' },
    { partnerName: 'Gebalko', LogoimageSrc: '/Partners/Gebalko/logo.png', buttonNumber: 33, imageSrc: '/image3.png', info: 'تاسست شركة جبالكو مصر عام 1984 و وهي إحدى الشركات المصرية التي تعمل في مجال المقاولات منذ أكثر من 35 عاماً حيث تم تسجيل شركة جبالكو مصر فى سجلات الأتحاد المصرى لمقاولى التشييد و البناء كفئة أولى لأعمال المتكاملة و الأعمال التكميلية و كذلك تم تسجيلها فى شبكات المياة و الصرف علاوة على أعمال الأشغال العامة و الأعمال الكهروميكانيكية و الألكترونية.' },
    { partnerName: 'Module', LogoimageSrc: '/Partners/Module/logo.png', buttonNumber: 34, imageSrc: '/image4.png', info: 'تم تأسيس شركة موديول عام 2019، وهي متخصصة في توريد المواد الغذائية. من بين أهم عملائها شركتي جوديز ايجيبت وإنفينيتي دايمنشن للتجارة والتوريدات.' },
    { partnerName: 'Al Kanana', buttonNumber: 35, imageSrc: '/image5.png', info: 'تأسست شركة الكنانه للأعلاف عام 2018 في محافظة المنوفية، حيث نشأت هذه الشركة كرمز للتميز والجودة في صناعة الأعلاف. ومنذ تأسيسها، حرصت شركة الكنانة على تقديم منتجات عالية الجودة وفي متناول الجميع. تعتبر شركة أعلاف الكنانة من الرواد في صناعة الأعلاف، حيث تمتاز بتقديم مجموعة متنوعة من المنتجات التي تلبي احتياجات جميع أصحاب المزارع، سواء كانوا مربين للماشية، الدواجن. تعتمد شركة أعلاف الكنانة على أحدث التقنيات في إنتاج الأعلاف حيث تعمل الشركة على توفير علف مواشي تسمين مختلفة التركيز.', LogoimageSrc: '/Partners/Al Kanana/logo.png' },
    {
      partnerName: 'El Safier', buttonNumber: 36,
      imageSrc: '/image5.png',
      info: 'تأسست شركه السفير عام ٢٠١٧ و هي تعمل في نشاط تعبئه و تغليف المواد الغذائيه و هي من الشركات الرائده بالسوق المصري لكونها تستوعب العديد من الطلبات مثل الزيت و السكر علي مستوي المحافظات',
      LogoimageSrc: '/Partners/Al Safir/logo.png'
    },
    { partnerName: 'Al Saif', buttonNumber: 37, imageSrc: '/image5.png', info: 'شركة السيف هى شركة رائدة ولها باع طويل وسنوات من الخبرة فى إحدى أهم مجالات مشتقات البترول وهو مجال إستيراد وتجارة خامات البلاستيك أو كما يطلق عليه اللدائن. شركة السيف تعمل منذ بداياتها فى خامات البلاستيك بكل أنواعها', LogoimageSrc: '/Partners/Al Saif/logo.png' },
    { partnerName: 'El Khattab', buttonNumber: 38, imageSrc: '/image5.png', info: 'تاسست شركه الخطاب ترافل عام ٢٠٠٤ و هي شركه لخدمات نقل العاملين و الموظفين بالشركات لديها العديد من الفروع و السيارات لخدمه النشاط من اهم العملاء نادي سيتي كلوب، اداره البنك الاهلي القطري بالسادات، شركه إيجيبشين ستار للسياحه', LogoimageSrc: '/Partners/Khatab/logo.png' },
    {
      partnerName: 'A M A', buttonNumber: 39, imageSrc: '/image5.png',
      info: ' تاسست الشركه عام ٢٠١٣ و هي تعمل مع شركات عالميه لنقل بضائعها مثل شركه شيبسي للصناعات الغذائيه، شركه بيبسي كولا مصر، مجموعه شركات جهينه و العديد من الشركات الاخري، كما ان الشركه تمتلك سيارات متنوعه للقدره علي نقل البضائع و استيعاب كافه طلبات و نقل منتجات السوق المصري'
      , LogoimageSrc: '/Partners/AMA/logo.png'
    },
    { partnerName: 'HF', LogoimageSrc: '/Partners/HF/logo.png', buttonNumber: 40, imageSrc: '/image5.png', info: 'تأسست مستشفي دكتور حنا فيهم عام ٢٠١٢ و هي من أكبر المستشفيات بمحافظه المنيا و تمتلك المستشفي العديد من الاجهزه الطبيه عاليه المستوي و تعمل علي خدمه جميع المحافظات المجاوره لها, لدي المستشفي أيضا طاقم دكاتره و تمريض حاصلين علي كافه الشهاده المطلوبه من مختلف التخصصات لدي المستشفي' },
    { partnerName: 'Pyramids', LogoimageSrc: '/Partners/Pyramids/logo.png', buttonNumber: 41, imageSrc: '/image5.png', info: 'تاسست شركه بيراميدز لتصنيع و تجاره الحديد و المعادن عام ٢٠٢٠ قامت الشركه بالتعاون مع مكتب استشاري لتوريد و تركيب جمالونات معدنيه لوزاره الداخليه و تركيب جمالونات لشركه هانيمكس للاستيراد و التصدير و قامت الشركه بالأعمال المعدنيه باستاد القاهره الدولي بالصاله المغطاه' },
    {
      partnerName: 'Afrofix',
      LogoimageSrc: '/Partners/Afrofix/logo.png',
      buttonNumber: 42,
      imageSrc: '/image5.png',
      info: 'تأسست الشركة الأفريقية للتجارة والهندسة عام 1981، وهي أول شركة مصنعة في مصر متخصصة في إنتاج أنظمة الدعم الكهروميكانيكية وقطع الغيار للمنشآت الصناعية والتجارية والمرافق العامة. تشمل منتجاتها أنظمة دعم الأنابيب الكاملة، ومشابك العوارض، والأقواس، وأنظمة تثبيت الكسوة الميكانيكية الكاملة، والمدخلات الخرسانية، ونظام الإطارات الفولاذية. تم استخدام منتجاتها من قبل العديد من المقاولين المصريين الرئيسيين مثل أوراسكوم للإنشاءات  OC، المقاولون العرب، حسن علام، ريدكون، نافكو، سي سي سي، كونتراك، وغيرهم في العديد من المشاريع في مصر والشرق الأوسط وأفريقيا. وهي شركة معتمدة من قبل العديد من دور الاستشارات الهندسية المرموقة مثل دار الهندسة، مجموعة الدكتور شاكر الاستشارية، ECG، صبور، rmc وغيرها.'
    }
    ,
    {
      partnerName: 'Al Shams', buttonNumber: 43, imageSrc: '/image5.png', info: 'Info for Button 13',
      LogoimageSrc: '/Partners/Alshams/logo.png'
    },
    {
      partnerName: 'Power Trading', buttonNumber: 44, imageSrc: '/image5.png', imageSrc: '/image5.png',
      info: 'تاسست شركه باور تريدنج عام ٢٠٠١ و هي تعمل في توريدات مستلزمات الدعايه و الإعلان لديها العديد من جهات التعامل',
      LogoimageSrc: '/Partners/Power Trading/logo.png'
    },
    {
      partnerName: 'EverGreen', buttonNumber: 45, imageSrc: '/image5.png', info: 'تعتبر شركة إيفر جرين للمقاولات الزراعية من الشركات الرائدة في مجال تنسيق الحدائق وصيانة المساحات الخضراء. تأسست الشركة عام 2000. يعمل بها نخبة من المهندسين المحترفين والإداريين الزراعيين والعمالة الزراعية على أعلى مستوى من الكفاءة والخبرة.'
      , LogoimageSrc: '/Partners/EverGreen/logo.png'
    },
    { partnerName: 'Azzam', buttonNumber: 46, imageSrc: '/image5.png', info: 'شركة عزام للأعلاف تأسست عام ٢٠١٨ وتعمل في نشاط تصنيع الأعلاف للأطراف الثالثة، وتمتلك الشركة عقود لتصنيع منتجاتها مع مصانع كبرى مثل مصنع الرواد لصناعة الأعلاف. من منتجات الشركة الرئيسية أعلاف الدواجن وأعلاف المواشي، مع اختلاف نسب التركيز لكل نوع من أنواع الأعلاف.', LogoimageSrc: '/Partners/Azzam/logo.png' },
    { partnerName: 'Misr October', buttonNumber: 47, imageSrc: '/image5.png', info: 'شركة مصر اكتوبر للأعلاف تأسست عام ٢٠١٦ وتعمل في نشاط تصنيع وإنتاج كافة أنواع الأعلاف وأيضًا تصنيع وإنتاج وعصر كافة أنواع البذور واستخلاص وتكرير الزيوت بكافة أنواعها وتعبئتها، علماً بأن المصنع على مساحة ٣١٠٠ متر مربع تقريباً وأيضًا تمتلك العديد من السيارات لخدمة النشاط في بيع المنتجات.', LogoimageSrc: '/Partners/Msr October/logo.png' },
    { partnerName: 'Tolba Gorup', buttonNumber: 48, imageSrc: '/image5.png', info: 'Info for Button 18', LogoimageSrc: '/Partners/Tolba/logo.png' },
    { partnerName: 'Mosbah', buttonNumber: 49, imageSrc: '/image5.png', info: 'تأسست شركه مصباح عام ٢٠٠٦ و هي تعمل في تجاره جميع المحاصيل الزراعيه من الخضار و الفاكهه الطازجه حصلت الشركه علي شهاده ال iso كما أنها لديها العديد من المحلات و الفروع و الثلاجات لحفظ جميع الخضار و الفاكهه علي مستوي محافظات الجمهوريه', LogoimageSrc: '/Partners/Mosbah/logo.png' },
    { partnerName: 'AlMasa', buttonNumber: 50, imageSrc: '/image5.png', info: 'تأسست شركة ألماسة لمواد التعبئة والتغليف عام 2013، وتعمل في مجال تجارة المواد الغذائية والتغليف وتوزيع مواد غذائية جافة. تعد المورد الرئيسي لمواد التعبئة والتغليف لعدد كبير من الشركات مثل قويدر للحلويات والسبيل الجديدة للدواجن. مؤخرًا، قامت الشركة بإضافة نشاط جديد في تصنيع أطباق وعبوات الفويل وأكياس لحفظ الطعام.', LogoimageSrc: '/Partners/Almasa/logo.png' },
    { partnerName: 'Vortex', LogoimageSrc: '/Partners/Vortex/logo.png', buttonNumber: 51, imageSrc: '/image5.png', info: 'تأسست شركة فورتكس عام 2020، وتعمل في مجال الدعاية والإعلان. تعمل على تغليف منتجات شركات مثل شركة السويدي للكابلات وشركة كريستال عصفور والبريد المصري. لدى الشركة قسم خاص بطباعة الدعايات والإعلانات.    ' },
    {
      partnerName: 'Instires', buttonNumber: 52, imageSrc: '/image5.png', info: 'تاسست شركه الطيب للتجاره و التوريد عام ٢٠١٥ و هي تعمل بتجارة و توريد الإطارات و البطاريات كما ان الشركه تقدم خدماتها من خلال عده مقرات و فروع و محلات تجزئه بالقاهره الكبري و عده محافظات اخري بالاضافه الي البيع عبر منصات الإنترنت'
      , LogoimageSrc: '/Partners/Instires/logo.png'
    },
    { partnerName: 'CIT', LogoimageSrc: '/Partners/CIT/logo.png', buttonNumber: 53, imageSrc: '/image5.png', info: 'تأسست شركه سي اي تي عام ٢٠١٧ و هي متخصصه في أعمال انتاج و تطوير البرامج و التطبيقات و إنشاء قواعد البيانات و نظم المعلومات الالكترونيه و تشغيلها كما ان الشركه نفذت العديد من المشاريع القوميه مثل مشروع الملاحه الجويه، مشروع ستاد هيئه قناه السويس و مشروع المنصوره ١ بالعاصمه الاداريه' },
    { partnerName: 'Molds Land', LogoimageSrc: '/Partners/MoldzLand/logo.png', buttonNumber: 54, imageSrc: '/image5.png', info: 'شركة مولدز لاند للبلاستيك نشأت عام 2017 وتقوم بتزويد السوق بأنواع مختلفة من المنتجات. الشركة تقوم بإنتاج عبوات لمجالات المنظفات ومستحضرات التجميل والمطهرات.' },
    { partnerName: 'Nozha', buttonNumber: 55, imageSrc: '/image5.png', info: 'تأسست شركة النزهة عام 1989، وتعمل في تجارة الأجهزة والمستلزمات والمستحضرات الطبية. تقدم الشركة خدمات الرعاية الصحية وتسعى إلى تحسين جودة الرعاية الصحية في مصر من خلال توفير معدات طبية عالية الجودة ومستلزمات طبية.', LogoimageSrc: '/Partners/Nozha/logo.png' },
    { partnerName: 'Abdo El Amir', buttonNumber: 56, imageSrc: '/image5.png', info: 'تم تأسيس شركة عبده الأمير عام 2015، وهي شركة رائدة في مجال العناية الفائقة بالسيارات في جمهورية مصر العربية. تمتلك الشركة عدة فروع على مستوى محافظات مصر وتتعامل مع العديد من الشركات العالمية.', LogoimageSrc: '/Partners/Alamir/logo.png' },
    { partnerName: 'Viochem', buttonNumber: 57, imageSrc: '/image5.png', info: 'تم تأسيس شركة فيو كيم للتجارة والصناعة عام 2012، وهي تختص في إنتاج وخلط وتجارة الخامات والمكسبات للطعم والرائحة. يقع المصنع على مساحة 3550 متر مربع وتمتلك أيضًا مخازن بمساحة 1400 متر مربع. الشركة تخدم عددًا من العملاء مثل شركة مودرن فودز، وشركة بيج كولا، وشركة أكتوبر للصناعات الغذائية.', LogoimageSrc: '/Partners/Viochem/logo.png' },
    { partnerName: 'Dr Ashraf', buttonNumber: 58, imageSrc: '/image5.png', info: 'نشأت موسسه دكتور اشرف عام ٢٠١٧ و هي متخصصه في توريد الادويه الطبيه و مركز بيع و تداول الادويه و اللقاحات البيطريه لدي المؤسسه كافه التراخيص المطلوبه بجانب الخبرات العلميه و العمليه التي تعمل بالمؤسسة و من اهم عملائها شركه الزهور و شركه فيت ماركت', LogoimageSrc: '/Partners/Dr Ashraf/logo.png' },
    { partnerName: 'Ashraf Gaber', buttonNumber: 59, imageSrc: '/image5.png', info: 'Info for Button 29', LogoimageSrc: '/Partners/Ashraf Gaber/logo.png' },
    { partnerName: 'Nogoum Alwady', buttonNumber: 60, imageSrc: '/image5.png', info: 'تأسس مصنع نجوم الوادي عام ٢٠١٧ حيث نشأ المصنع على ٧٠٠٠ متر مربع لإنتاج وتصنيع جميع منتجات أعلاف الدواجن. الشركة تمتلك أسطول نقل كامل لخدمة جميع محافظات مصر، وتتمتع بخبرة في تصنيع الأعلاف مع الالتزام بكافة معايير الجودة، وتعتمد على أفضل المواد النباتية الخالية من السموم والفطريات.', LogoimageSrc: '/Partners/Nogom Alwady/logo.png' },
  ];
  const [selectedButton, setSelectedButton] = useState(null);
  const [headlineDisplay, setHeadlineDisplay] = useState('block');
  const [imageDisplay, setImageDisplay] = useState('none');
  const [currentData, setCurrentData] = useState(data[0]);
  const [defaultDesDisplay, setDefaultDesDisplay] = useState('block');
  const [partnerDesDisplay, setPartnerDesDisplay] = useState('none');


  // const handleButtonClick = async (buttonNumber) => {
  //   try {

  //     // // Wrap the find operation in a Promise
  //     // const findClickedData = () => {
  //     //   return new Promise((resolve, reject) => {
  //     //     const clickedData = data.find((item) => item.buttonNumber === buttonNumber);
  //     //     if (clickedData) {
  //     //       resolve(clickedData);
  //     //     } else {
  //     //       reject(new Error('Clicked data not found'));
  //     //     }
  //     //   });
  //     // };

  //     // Use async/await to wait for the Promise to resolve
  //     // const clickedData = await findClickedData();

  //     // // Update the state to hide the default description and show partner description
  //     // setDefaultDesDisplay('none');
  //     // setPartnerDesDisplay('block');

  //     // // Update the state with the data for the clicked button
  //     // setHeadlineDisplay('none');
  //     // setCurrentData(clickedData);
  //     // setImageDisplay('block');
  //     // Set the selected button
  //     // setSelectedButton(buttonNumber);
  //   } catch (error) {
  //     console.error(error);
  //     // Handle the error as needed
  //   }
  // };

  const handleReset = () => {
    setSelectedButton(null);
    setHeadlineDisplay('block');
    setImageDisplay('none');
    setCurrentData(data[0]);
    setDefaultDesDisplay('block');
    setPartnerDesDisplay('none');
  };


  const [selectedPage, setSelectedPage] = useState(0);
  const renderCustomArrow = (direction, clickHandler, isEnabled, label) => (
    <div className={styles.arrowsContainer2}>
      {direction === 'prev' && (
        <button
          className={styles.leftarrow}
          onClick={() => {
            clickHandler();
            setSelectedPage((prevPage) => (prevPage === 0 ? sectors.length - 1 : prevPage - 1));
          }}
          disabled={!isEnabled}
          aria-label={label}
        ></button>
      )}
      {/* Numbered buttons */}
      {direction !== 'prev' && (
        <div className={styles.arrows2}>
          {sectors.map((_, index) => (
            <button
              key={index}
              className={`${styles.pageNumber2} ${index === selectedPage ? styles.selectedPage2 : ''}`}
            // onClick={() => setSelectedPage(index)}
            >
              {(index + 1).toLocaleString('ar')}
            </button>
          ))}
        </div>
      )}
      {direction === 'next' && (
        <button
          className={styles.rightarrow}
          onClick={() => {
            clickHandler();
            setSelectedPage((prevPage) => (prevPage === sectors.length - 1 ? 0 : prevPage + 1));
          }}
          disabled={!isEnabled}
          aria-label={label}
        ></button>
      )}
    </div>
  );

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    // Trigger animation after the component has mounted
    setAnimate(true);
  }, []);


  return (
    <div className={styles.pageContainer}>
      <div className={styles.lefttopCont22}>
        <div className={styles.headline} style={{ display: headlineDisplay }}>
          <div className={styles.PartTitles} style={{ display: 'flex', flexDirection: 'row' }} >
            <h1>شركــاء </h1>
          </div>
          <h2>النجــاح</h2>
        </div>
        <div className={styles.partnerlogo}>
          <img
            src={currentData.imageSrc}
            alt={`Image for Button ${currentData.buttonNumber}`}
            style={{ display: imageDisplay }}
          />

        </div>

      </div>
      <div className={styles.rightbottomCont2}>

        <div className={styles.defaultdes} style={{ display: defaultDesDisplay }}>
          <div className={styles.des22}  >
            <div className={styles.des122} >
              <h1>٥۰۰ +</h1>
              <h2>عميـــل</h2>
            </div>
            <div className={styles.des222} >

              <h1>
                <span style={{ color: '#DDB96E' }}>هدفنا</span>   بناء علاقات فعّالة، و المساهمة في نجاح المشاريع في مختلف القطاعات
              </h1>
            </div>
          </div></div>
        <div className={styles.partnerdes} style={{ display: partnerDesDisplay, width: `100%` }}>
          <div className={styles.des2} >

            <h1 style={{ marginLeft: `7%` }}>
              {currentData && currentData.info}
            </h1>
          </div>

        </div>


      </div>
      {/* Carousel */}
      <Carousel
        className={styles.Carousel}
        animationHandler={fadeAnimationHandler}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        showIndicators={false}
        showArrows={true} // Enable arrows
        renderArrowNext={(clickHandler1, hasNext, label) =>
          renderCustomArrow('next', clickHandler1, hasNext, label)
        }
        renderArrowPrev={(clickHandler2, hasPrev, label) =>
          renderCustomArrow('prev', clickHandler2, hasPrev, label)
        }

      >

        {sectors.map((sector, index) => (
          <div className={styles.CarouselCont} key={index} style={{ position: 'relative' }}>
            {index === 0 && (
              <>
                {/* Overlay for Manufacturing */}
                <div className={styles.overpageCont}>
                  <div className={styles.slidecontainer2}>
                    <div className={styles.leftCont2}>
                      <div className={styles.lefttopCont22}>

                      </div>
                      <div className={styles.leftbottomCont}>
                        <div className={styles.contpartners}>
                          <div className={styles.Subcontpartners}>


                            <button
                              className={selectedButton === 1 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(1)}
                            >
                              {data[0].partnerName}
                            </button>

                            <button
                              className={selectedButton === 2 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(2)}
                            >
                              {data[1].partnerName}
                            </button>

                            <button
                              className={selectedButton === 3 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(3)}
                            >
                              {data[2].partnerName}
                            </button>

                            <button
                              className={selectedButton === 4 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(4)}
                            >
                              {data[3].partnerName}
                            </button>
                            <button
                              className={selectedButton === 5 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(5)}
                            >
                              {data[4].partnerName}
                            </button>
                          </div>
                          <div className={styles.Subcontpartners}>

                            <button
                              className={selectedButton === 6 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(6)}
                            >
                              {data[5].partnerName}
                            </button>
                            <button
                              className={selectedButton === 7 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(7)}
                            >
                              {data[6].partnerName}
                            </button>
                            <button
                              className={selectedButton === 8 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(8)}
                            >
                              {data[7].partnerName}
                            </button>
                            <button
                              className={selectedButton === 9 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(9)}
                            >
                              {data[8].partnerName}
                            </button>
                            <button
                              className={selectedButton === 10 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(10)}
                            >
                              {data[9].partnerName}
                            </button>
                          </div>
                          <div className={styles.Subcontpartners}>

                            <button
                              className={selectedButton === 11 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(11)}
                            >
                              {data[10].partnerName}
                            </button>
                            <button
                              className={selectedButton === 12 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(12)}
                            >
                              {data[11].partnerName}
                            </button>
                            <button
                              className={selectedButton === 13 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(13)}
                            >
                              {data[12].partnerName}
                            </button>
                            <button
                              className={selectedButton === 14 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(14)}
                            >
                              {data[13].partnerName}
                            </button>
                            <button
                              className={selectedButton === 15 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(15)}
                            >
                              {data[14].partnerName}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className={styles.rightCont2}>
                      <div className={styles.righttopCont}>
                        <div className={styles.contpartners}>
                          <div className={styles.Subcontpartners}>


                            <button
                              className={selectedButton === 16 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(16)}
                            >
                              {data[15].partnerName}
                            </button>

                            <button
                              className={selectedButton === 17 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(17)}
                            >
                              {data[16].partnerName}
                            </button>

                            <button
                              className={selectedButton === 18 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(18)}
                            >
                              {data[17].partnerName}
                            </button>

                            <button
                              className={selectedButton === 19 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(19)}
                            >
                              {data[18].partnerName}
                            </button>
                            <button
                              className={selectedButton === 20 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(20)}
                            >
                              {data[19].partnerName}
                            </button>
                          </div>

                          <div className={styles.Subcontpartners}>


                            <button
                              className={selectedButton === 21 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(21)}
                            >
                              {data[20].partnerName}
                            </button>

                            <button
                              className={selectedButton === 22 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(22)}
                            >
                              {data[21].partnerName}
                            </button>

                            <button
                              className={selectedButton === 23 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(23)}
                            >
                              {data[22].partnerName}
                            </button>

                            <button
                              className={selectedButton === 24 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(24)}
                            >
                              {data[23].partnerName}
                            </button>
                            <button
                              className={selectedButton === 25 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(25)}
                            >
                              {data[24].partnerName}
                            </button>
                          </div>

                          <div className={styles.Subcontpartners}>


                            <button
                              className={selectedButton === 26 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(26)}
                            >
                              {data[25].partnerName}
                            </button>

                            <button
                              className={selectedButton === 27 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(27)}
                            >
                              {data[26].partnerName}
                            </button>

                            <button
                              className={selectedButton === 28 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(28)}
                            >
                              {data[27].partnerName}
                            </button>

                            <button
                              className={selectedButton === 29 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(29)}
                            >
                              {data[28].partnerName}
                            </button>
                            <button
                              className={selectedButton === 30 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(30)}
                            >
                              {data[29].partnerName}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Content of the Slide */}


              </>
            )}

            {index === 1 && (
              <>

                {/* Overlay for Trading */}
                <div className={styles.overpageCont}>
                  <div className={styles.slidecontainer2}>



                    <div className={styles.leftCont2}>
                      <div className={styles.lefttopCont22}>

                      </div>
                      <div className={styles.leftbottomCont}>
                        <div className={styles.contpartners}>
                          <div className={styles.Subcontpartners}>


                            <button
                              className={selectedButton === 31 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(31)}
                            >
                              {data[30].partnerName}
                            </button>

                            <button
                              className={selectedButton === 32 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(32)}
                            >
                              {data[31].partnerName}
                            </button>

                            <button
                              className={selectedButton === 33 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(33)}
                            >
                              {data[32].partnerName}
                            </button>

                            <button
                              className={selectedButton === 34 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(34)}
                            >
                              {data[33].partnerName}
                            </button>
                            <button
                              className={selectedButton === 35 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(35)}
                            >
                              {data[34].partnerName}
                            </button>
                          </div>
                          <div className={styles.Subcontpartners}>

                            <button
                              className={selectedButton === 36 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(36)}
                            >
                              {data[35].partnerName}
                            </button>
                            <button
                              className={selectedButton === 37 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(37)}
                            >
                              {data[36].partnerName}
                            </button>
                            <button
                              className={selectedButton === 38 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(38)}
                            >
                              {data[37].partnerName}
                            </button>
                            <button
                              className={selectedButton === 39 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(39)}
                            >
                              {data[38].partnerName}
                            </button>
                            <button
                              className={selectedButton === 40 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(40)}
                            >
                              {data[39].partnerName}
                            </button>
                          </div>
                          <div className={styles.Subcontpartners}>

                            <button
                              className={selectedButton === 41 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(41)}
                            >
                              {data[40].partnerName}
                            </button>
                            <button
                              className={selectedButton === 42 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(42)}
                            >
                              {data[41].partnerName}
                            </button>
                            <button
                              className={selectedButton === 43 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(43)}
                            >
                              {data[42].partnerName}
                            </button>
                            <button
                              className={selectedButton === 44 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(44)}
                            >
                              {data[43].partnerName}
                            </button>
                            <button
                              className={selectedButton === 45 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(45)}
                            >
                              {data[44].partnerName}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                    <div className={styles.rightCont2}>
                      <div className={styles.righttopCont}>
                        <div className={styles.contpartners}>
                          <div className={styles.Subcontpartners}>


                            <button
                              className={selectedButton === 46 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(46)}
                            >
                              {data[45].partnerName}
                            </button>

                            <button
                              className={selectedButton === 47 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(47)}
                            >
                              {data[46].partnerName}
                            </button>

                            <button
                              className={selectedButton === 48 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(48)}
                            >
                              {data[47].partnerName}
                            </button>

                            <button
                              className={selectedButton === 49 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(49)}
                            >
                              {data[48].partnerName}
                            </button>
                            <button
                              className={selectedButton === 50 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(50)}
                            >
                              {data[49].partnerName}
                            </button>

                          </div>

                          <div className={styles.Subcontpartners}>

                            <button
                              className={selectedButton === 51 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(51)}
                            >
                              {data[50].partnerName}
                            </button>

                            <button
                              className={selectedButton === 52 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(52)}
                            >
                              {data[51].partnerName}
                            </button>

                            <button
                              className={selectedButton === 53 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(53)}
                            >
                              {data[52].partnerName}
                            </button>

                            <button
                              className={selectedButton === 54 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(54)}
                            >
                              {data[53].partnerName}
                            </button>
                            <button
                              className={selectedButton === 55 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(55)}
                            >
                              {data[54].partnerName}
                            </button>
                          </div>

                          <div className={styles.Subcontpartners}>


                            <button
                              className={selectedButton === 56 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(56)}
                            >
                              {data[55].partnerName}
                            </button>

                            <button
                              className={selectedButton === 57 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(57)}
                            >
                              {data[56].partnerName}
                            </button>

                            <button
                              className={selectedButton === 58 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(58)}
                            >
                              {data[57].partnerName}
                            </button>

                            <button
                              className={selectedButton === 59 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(59)}
                            >
                              {data[58].partnerName}
                            </button>
                            <button
                              className={selectedButton === 60 ? styles.SelectedPartnerbutton : styles.unSelectedPartnerbutton}
                              onClick={() => handleButtonClick(60)}
                            >
                              {data[59].partnerName}
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


              </>
            )}

            {index === 2 && (
              <>

                {/* Overlay for Tech */}
                <div className='overlay'>





                </div>
                {/* Content of the Slide */}



              </>
            )}

            {index === 3 && (
              <>

                {/* Overlay for Transportation */}
                <div className='overlay'>

                </div>
                {/* Content of the Slide */}

              </>
            )}

            {index === 4 && (
              <>

                {/* Overlay for Construction */}
                <div className='overlay'>

                </div>
                {/* Content of the Slide */}


              </>
            )}
          </div>
        ))}

      </Carousel>

    </div>
  );
};

export default Partners;