import React, { useState, useEffect, useRef } from 'react';
import Sliderservices from './Sliderservices';
import styles from './Services.module.css';
import { useLocation } from 'react-router-dom';
import '../../index.css';
import Footer from '../Footer/Footer'
import BottomButton from '../Footer/BottomButton'
import debounce from 'lodash/debounce'; // Import debounce function
const framesData = [
    {
        head1: 'Car',
        head2: 'Services',
        description: 'Our goal is to meet all your automotive needs with high-quality service and expertise.',
        imageSrc: '/car.png',
        frame1: 'Tires Services',
        frame2: 'Cleaning Services',
        frame3: 'Porcelain car wrap',
        frame4: null,
        frame5: null,
        frame6: null
    },
    {
        head1: 'Home',
        head2: 'Services',
        description: 'Our home services cover everything you need for a comfortable and modern living space.',
        imageSrc: '/Home.png',
        frame1: 'Smart Home solutions',
        frame2: 'Cleaning Services',
        frame3: 'Finishing Services',
        frame4: 'Installing Cameras',
        frame5: 'Furniture',
        frame6: null
    },
    {
        head1: 'Transportation, Travels ',
        head2: 'and excursion services',
        head3: '(Inside Egypt only)',
        description: 'Our travel services cater to both Domestic Tourism and International Tourism.',
        imageSrc: '/travel.png',
        frame1: 'Domestic Tourism',
        frame2: 'Foreign Tourism',
        frame3: null,
        frame4: null,
        frame5: null,
        frame6: null
    },
    {
        head1: 'Technology',
        head2: 'Services',
        description: 'Our technology services provide comprehensive solutions to meet your digital needs.',
        imageSrc: '/tech.png',
        frame1: 'Smart Home',
        frame2: 'Camera installation',
        frame3: 'Websites & Mobile apps Development',
        frame4: null,
        frame5: null,
        frame6: null
    },
];

const Services = () => {
    const [showBottomButton, setShowBottomButton] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialPageNumber = Number(queryParams.get('pagenumb')) || 1;
    const [currentSlide, setCurrentSlide] = useState(initialPageNumber - 1);
    const totalSlides = framesData.length;
    const slideWidth = 100;
    const footerIconsRef = useRef(null);

    const handleNextClick = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    };

    const handlePrevClick = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
    };

    const slidesrowStyle = {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        width: `${totalSlides * slideWidth}%`,
        transform: `translateX(-${currentSlide * (100 / totalSlides)}%)`,
        transition: 'transform 0.5s ease-in-out',
    };

    useEffect(() => {
        const handleScroll = debounce((event) => {
            if (event.deltaY > 0) {
                handleNextClick();
            } else if (event.deltaY < 0) {
                handlePrevClick();
            }
        }, 200);

        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [currentSlide]);

    useEffect(() => {
        const footerIcons = document.getElementById('footericons');
        const observer = new IntersectionObserver(handleIntersect, {
            root: null,
            threshold: 0.1,
        });

        if (footerIcons) {
            observer.observe(footerIcons);
        }

        return () => {
            if (footerIcons) {
                observer.unobserve(footerIcons);
            }
        };
    }, []);

    const handleIntersect = (entries) => {
        entries.forEach((entry) => {
            setShowBottomButton(!entry.isIntersecting);
        });
    };

    return (
        <div>
            <div className='overlay'></div>
            <div className={styles.mobPageContainer} style={{ overflowY: 'auto', maxHeight: '100vh', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
                <div className={styles.submobPageContainer}>
                    {framesData.map((data, index) => (
                        <div key={index} style={{ marginBottom: '15%' }}>
                            <div className={styles.mobimageCont}>
                                <img src={data.imageSrc} alt="" />
                            </div>
                            <div className={styles.mobText}>
                                <h2 className={styles.mobHead1}>{data.head1}</h2>
                                <h2 className={styles.mobHead2}>{data.head2}</h2>
                                <div className={styles.mobDisCont}><p>{data.description}</p></div>
                            </div>
                            {
                                [data.frame1, data.frame2, data.frame3, data.frame4, data.frame5, data.frame6].map((frame, frameIndex) => (
                                    frame && (
                                        <div key={frameIndex} className={styles.mobshapecontainer}>
                                            <div className={styles.mobnumbline}>
                                                <div className={styles.mobnumberingshape}>
                                                    <span className={styles.mobnumber}>{frameIndex + 1}</span>
                                                </div>
                                                <hr className={styles.mobseparator} />
                                            </div>
                                            <div className={styles.mobframe}>
                                                <h3>{frame}</h3>
                                            </div>
                                        </div>
                                    )
                                ))
                            }
                        </div >
                    ))}
                </div >
                <div style={{ zIndex: '99' }}>  <Footer></Footer></div>

                {showBottomButton && <BottomButton ref={footerIconsRef}></BottomButton>}
            </div >
            <div className={styles.pageContainer}>
                <div className='overlay' style={{ display: 'block', position: 'fixed' }}>
                    <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                            <div key={index} style={{ width: `${slideWidth}%` }}>
                                <Sliderservices data={data} />
                            </div>
                        ))}
                    </div>
                    <div className={styles.slidersbuttons}>
                        <button className={styles.leftarrow} onClick={handlePrevClick}></button>
                        {[...Array(totalSlides).keys()].map((pageNum) => (
                            <button
                                key={pageNum}
                                className={`${styles.pageNumber} ${pageNum === currentSlide ? styles.selectedPage : ''}`}
                                onClick={() => setCurrentSlide(pageNum)}
                            >
                                {pageNum + 1}
                            </button>
                        ))}
                        <button className={styles.rightarrow} onClick={handleNextClick}></button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Services;
// http://localhost:3000/financialservices/aas?pagenumb=2