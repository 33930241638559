import React, { useEffect, useState, useRef } from 'react';
import styles from './Hero.module.css';
import style from '../LandingPage/Home.module.css'
import IconsSocial from '../Social-icons/IconsSocial';
import '../../index.css'
import { isVisible } from '@testing-library/user-event/dist/utils';
const Hero = ({ isVisible, backFromDot }) => {
    const [animate, setAnimate] = useState(false);
    const [leaveAnimate, SetLeaveAnimate] = useState(false);
    useEffect(() => {
        // Trigger animation after the component has mounted
        setAnimate(true);
        SetLeaveAnimate(isVisible);
    }, [isVisible]);

    function animateValue(ref, start, end, duration) {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / duration, 1);
            if (ref.current) {
                ref.current.innerHTML = Math.floor(progress * (end - start) + start);
            }
            if (progress < 1) {
                window.requestAnimationFrame(step);
            }
        };
        window.requestAnimationFrame(step);
    }

    const NumberCounter = ({ start, end, duration }) => {
        const ref = useRef(null);

        useEffect(() => {
            if (ref.current) {
                animateValue(ref, start, end, duration);
            }
        }, [start, end, duration]);

        return <span ref={ref}>0</span>;
    };

    return (


        <div className='overlay'>
            <div className={`${styles.cont} `}>
                <div className={styles.finance}>
                    <div className={`${styles.headline} `} style={{ display: 'flex', flexDirection: 'row' }} >
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-100%)', transition: 'transform .5s' }}>Y</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-200%)', transition: 'transform .5s' }}>o</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-300%)', transition: 'transform .5s' }}>u</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-400%)', transition: 'transform .5s' }}>r</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-500%)', transition: 'transform .5s' }}> &nbsp;</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-600%)', transition: 'transform .5s' }}>B</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-700%)', transition: 'transform .5s' }}>e</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-800%)', transition: 'transform .5s' }}>s</h1>
                        <h1 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-900%)', transition: 'transform .5s' }}>t</h1>
                    </div>
                    <div className={`${styles.headline} ${styles.fadeInThird}`}>
                        <h2>Financial</h2>
                        <h2>solutions</h2>
                    </div>
                    <div className={`${styles.des} ${styles.fadeInThird} `}>
                        <p>
                            We are a Financial Consulting Company offering essential methods for both large and small projects, aiding companies and institutions.
                        </p>
                    </div>
                    <div className={styles.numbers}>
                        <div className={styles.count}>
                            <div className={`${styles.numbCont}`}>

                                <div className={styles.subnumber}>
                                    <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row' }} >
                                        <h1>+</h1>
                                        <h2><NumberCounter start={0} end={14} duration={5000} /></h2>
                                    </div>

                                </div>
                                <div className={styles.subMainTitleWhite} style={{ display: 'flex', flexDirection: 'row' }} >
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-100%)', transition: 'transform .5s' }}>Y</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-200%)', transition: 'transform .5s' }}>e</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-300%)', transition: 'transform .5s' }}>a</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-400%)', transition: 'transform .5s' }}>r</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-500%)', transition: 'transform .5s' }}>s</h2>
                                </div>
                            </div>
                            <div className={`${styles.numbCont} `}>
                                <div className={styles.subnumber}>
                                    <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row' }} >
                                        <h1  >+</h1>
                                        <h2><NumberCounter start={0} end={500} duration={5000} /></h2>
                                    </div>

                                </div>
                                <div className={styles.subnumber}>
                                    <div className={styles.subMainTitleWhite} style={{ display: 'flex', flexDirection: 'row' }} >
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-100%)', transition: 'transform .5s' }}>H</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-200%)', transition: 'transform .5s' }}>a</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-300%)', transition: 'transform .5s' }}>p</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-400%)', transition: 'transform .5s' }}>p</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-500%)', transition: 'transform .5s' }}>y</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-600%)', transition: 'transform .5s' }}>&nbsp;</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-700%)', transition: 'transform .5s' }}>C</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-800%)', transition: 'transform .5s' }}>l</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-900%)', transition: 'transform .5s' }}>i</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-1000%)', transition: 'transform .5s' }}>e</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-1100%)', transition: 'transform .5s' }}>n</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-1200%)', transition: 'transform .5s' }}>t</h2>
                                        <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-1300%)', transition: 'transform .5s' }}>s</h2>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.numbCont} `}>
                                <div className={styles.subnumber}>
                                    <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row' }} >
                                        <h1  >+</h1>
                                        <h2><NumberCounter start={0} end={80} duration={5000} /></h2>

                                    </div>
                                </div>
                                <div className={styles.subMainTitleWhite} style={{ display: 'flex', flexDirection: 'row' }} >
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-100%)', transition: 'transform .5s' }}>P</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-200%)', transition: 'transform .5s' }}>a</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-300%)', transition: 'transform .5s' }}>r</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-400%)', transition: 'transform .5s' }}>t</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-500%)', transition: 'transform .5s' }}>n</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-600%)', transition: 'transform .5s' }}>e</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-700%)', transition: 'transform .5s' }}>r</h2>
                                    <h2 style={{ transform: animate ? 'translateY(0%)' : 'translateY(-800%)', transition: 'transform .5s' }}>s</h2>
                                </div></div>
                        </div>
                    </div>
                </div>
                <div className={styles.Coinbg}>
                    <img src="./hero-image.png" alt="" />
                </div>

            </div>
        </div >

    );
}

export default Hero;