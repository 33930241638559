import React, { useEffect, useState } from 'react';
import styles from './Home.module.css';
import IconsSocial from '../Social-icons/IconsSocial';
import '../../index.css'


function Home() {
    const [animate, setAnimate] = useState(false);
    const [animate2, setAnimate2] = useState(false);

    const [showSecondPart, setShowSecondPart] = useState(false);

    useEffect(() => {
        // Trigger animation when the component mounts
        setAnimate(true);

        // Hide the first part and show the second part after 5 seconds
        const timer = setTimeout(() => {
            setShowSecondPart(true);


        }, 7000);
        const timer2 = setTimeout(() => {

            setAnimate2(true);

        }, 7050);
        // Cleanup function to clear the timer
        return () => {
            clearTimeout(timer);
            clearTimeout(timer2);
        };
    }, []);
    return (
        <>

            <div className={styles.overlay}>
                <div className={styles.cont} style={{ flexDirection: 'row-reverse' }}>
                    <div className={styles.finance} style={{ marginLeft: '0', width: '100%', marginRight: '10%' }}>
                        {showSecondPart ? <SecondPartContent animate={animate2} /> : <FirstPartContent animate={animate} />}

                        <div className={styles.numbers2}>
                            <div className={styles.count2}>
                                <div className={`${styles.numbCont}`}>

                                    <div className={styles.subnumber}>
                                        <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                            <h1>+</h1>
                                            <h2>۱٤</h2>
                                        </div>

                                    </div>
                                    <div className={styles.subMainTitleWhite2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                        <h2>سنة خبرة</h2>

                                    </div>
                                </div>
                                <div className={`${styles.numbCont} `}>
                                    <div className={styles.subnumber}>
                                        <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                            <h1>+</h1>
                                            <h2>٥۰۰</h2>
                                        </div>

                                    </div>
                                    <div className={styles.subnumber}>
                                        <div className={styles.subMainTitleWhite2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                            <h2>عميل</h2>

                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.numbCont} `}>
                                    <div className={styles.subnumber}>
                                        <div className={styles.subTitleYellow2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                            <h1>+</h1>
                                            <h2>۸۰</h2>
                                        </div>
                                    </div>
                                    <div className={styles.subMainTitleWhite2} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                                        <h2>شريك</h2>

                                    </div>                            </div>
                            </div>
                        </div >

                    </div >
                    <div className={styles.ship_ar}>
                        <img src="/empty-ship_ar.svg" alt="Ship Icon" />

                    </div>
                    <div className={styles.ship2_ar}>
                        <img src="/Full-Ship_ar.svg" alt="Ship Icon" />

                    </div>
                    <div className={styles.bg}></div>

                </div >
            </div >
        </>
    );
}

function FirstPartContent({ animate }) {
    return (
        <>
            <div className={`${styles.headline2} `} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                <h1>تصدير المنتجات</h1>
            </div>
            <div className={`${styles.headline2} `} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                <h2>للأسواق الخارجية</h2>
            </div>

            <div className={`${styles.des2}`}>
                <p>
                    من الامتثال الجمركي وصولاً إلى إدارة اللوجستيات، بنكنوت تساعد مشروعك  ليزدهر في السوق العالمية
                </p>
            </div>
        </>

    );
}

function SecondPartContent({ animate }) {
    return (

        <>
            <div className={`${styles.headline2} `} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                <h1> استثمر نشاطك</h1>
            </div>
            <div className={`${styles.headline2} `} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                <h2>التجاري واعرض </h2>
            </div>
            <div className={`${styles.headline2} `} style={{ display: 'flex', flexDirection: 'row-reverse' }} >
                <h2>منتجاتك معنا</h2>
            </div>
            <div className={`${styles.des2}`}>
                <p>
                    بنكنوت تساعدك في تنمية نشاطك التجاري من خلال الترويج لمنتجاتك  و تسهيل اجرائات التجارة و التصدير            </p>
            </div>
        </>
    );
}

export default Home;