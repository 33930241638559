import React, { useState, useEffect, useRef } from 'react';
import styles from './Services.module.css';
import { useLocation } from 'react-router-dom';
import '../../index.css';
import Footer from '../Footer/Footer'
import BottomButton from '../Footer/BottomButton'
import debounce from 'lodash/debounce'; // Import debounce function
import Sliderservices_ar from './Sliderservices_ar';
const framesData = [
    {
        head1: 'خدمات',
        head2: 'السيارات ',
        description: 'هدفنا هو تلبية جميع احتياجاتك في مجال السيارات بخدمة عالية الجودة وخبرة متخصصة.',
        imageSrc: '/car.png',
        frame1: 'خدمات الإطارات ',
        frame2: 'خدمات التنظيف',
        frame3: 'تغليف السيارات ',
        frame4: null,
        frame5: null,
        frame6: null
    },
    {
        head1: 'خدمات',
        head2: 'المنزل',
        description: 'تشمل خدمات المنزل كل ما تحتاجه للحصول على مساحة معيشة مريحة وعصرية.',
        imageSrc: '/Home.png',
        frame1: 'حلول المنزل الذكية',
        frame2: 'خدمات التنظيف  ',
        frame3: ' خدمات التشطيب',
        frame4: 'تركيب الكاميرات',
        frame5: 'الأثاث',
        frame6: null
    },
    {
        head1: 'خدمات',
        head2: 'التكنولوجيا',
        description: 'تقدم خدماتنا التكنولوجية حلولا شاملة لتلبية احتياجاتك الرقمية.',
        imageSrc: '/tech.png',
        frame1: 'حلول المنزل الذكية',
        frame2: 'تركيب الكاميرات',
        frame3: 'تطوير المواقع الالكترونية و التطبيقات',
        frame4: null,
        frame5: null,
        frame6: null
    },
    {
        head1: 'خدمات',
        head2: 'النقل والرحلات',
        head3: '(داخل مصر فقط)',
        description: 'نقدم خدماتنا في مجال السفر و اﻻنتقالات الداخلية',
        imageSrc: '/travel.png',
        frame1: 'اتوبيسات سياحيه',
        frame2: 'خدمة ليموزين ',
        frame3: null,
        frame4: null,
        frame5: null,
        frame6: null
    },
];

const Services_ar = () => {
    const [showBottomButton, setShowBottomButton] = useState(true);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialPageNumber = Number(queryParams.get('pagenumb')) || 1;
    const [currentSlide, setCurrentSlide] = useState(initialPageNumber - 1);
    const totalSlides = framesData.length;
    const slideWidth = 100;
    const footerIconsRef = useRef(null);
    const handleNextClick = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    };

    const handlePrevClick = () => {
        setCurrentSlide((prevSlide) => (prevSlide - 1 + totalSlides) % totalSlides);
    };

    const slidesrowStyle = {
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        width: `${totalSlides * slideWidth}%`,
        transform: `translateX(-${currentSlide * (100 / totalSlides)}%)`,
        transition: 'transform 0.5s ease-in-out',
    };

    useEffect(() => {
        const handleScroll = debounce((event) => {
            if (event.deltaY > 0) {
                handleNextClick();
            } else if (event.deltaY < 0) {
                handlePrevClick();
            }
        }, 200);

        window.addEventListener('wheel', handleScroll);

        return () => {
            window.removeEventListener('wheel', handleScroll);
        };
    }, [currentSlide]);

    useEffect(() => {
        const footerIcons = document.getElementById('footericons');
        const observer = new IntersectionObserver(handleIntersect, {
            root: null,
            threshold: 0.1,
        });

        if (footerIcons) {
            observer.observe(footerIcons);
        }

        return () => {
            if (footerIcons) {
                observer.unobserve(footerIcons);
            }
        };
    }, []);

    const handleIntersect = (entries) => {
        entries.forEach((entry) => {
            setShowBottomButton(!entry.isIntersecting);
        });
    };

    return (
        <div>
            <div className='overlay' style={{}}></div>
            <div className={styles.mobPageContainer} style={{ overflowY: 'auto', maxHeight: '100vh', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}>
                <div className={styles.submobPageContainer}>
                    <div className={styles.mobTitle2}>
                        <h1>
                            الخدمات  <span style={{ color: '#EBD5A8' }}>المالية</span>
                        </h1>
                    </div>
                    {framesData.map((data, index) => (
                        <div key={index} style={{ marginBottom: '15%' }} >
                            <div className={styles.mobimageCont}>
                                <img src={data.imageSrc} alt="" />
                            </div>
                            <div className={styles.mobText}>
                                <h2 className={styles.mobHead12}>{data.head1}</h2>
                                <h2 className={styles.mobHead22}>{data.head2}</h2>
                                <div className={styles.mobDisCont2}><p>{data.description}</p></div>
                            </div>
                            {Object.entries(data)
                                .slice(3)
                                .filter(([key]) => key.startsWith('frame'))
                                .map(([key, value], frameIndex) => (
                                    value !== null && (
                                        <div key={frameIndex} className={styles.mobshapecontainer}>
                                            <div className={styles.mobnumbline2}>
                                                <div className={styles.mobnumberingshape}>
                                                    <span style={{ fontFamily: 'Cairo Light' }} className={styles.mobnumber}>
                                                        {(frameIndex + 1).toLocaleString('ar')}
                                                    </span>
                                                </div>
                                                <hr className={styles.mobseparator2} />
                                            </div>
                                            <div className={styles.mobframe2}>
                                                <h3>{value}</h3>
                                            </div>
                                        </div>
                                    )
                                ))
                            }


                        </div>
                    ))}

                </div>
                <div style={{ zIndex: '99' }}>  <Footer></Footer></div>
                {showBottomButton && <BottomButton ref={footerIconsRef}></BottomButton>}
            </div>
            <div className={styles.pageContainer}>
                <div className='overlay' style={{ display: 'block', position: 'fixed' }}>
                    <div style={slidesrowStyle}>
                        {framesData.map((data, index) => (
                            <div key={index} style={{ width: `${slideWidth}%` }}>
                                <Sliderservices_ar data={data} />
                            </div>
                        ))}
                    </div>
                    <div className={styles.slidersbuttons} style={{ marginLeft: '3%', gap: '0' }}>
                        <button className={styles.leftarrow} onClick={handleNextClick}></button>
                        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                            {[...Array(totalSlides).keys()].map((pageNum) => (
                                <button
                                    style={{ fontFamily: 'Cairo Light', lineHeight: '10%' }}
                                    key={pageNum}
                                    className={`${styles.pageNumber} ${pageNum === currentSlide ? styles.selectedPage : ''
                                        }`}
                                    onClick={() => setCurrentSlide(pageNum)}
                                >
                                    {(pageNum + 1).toLocaleString('ar-EG')}
                                </button>
                            ))}
                        </div>


                        <button className={styles.rightarrow} onClick={handlePrevClick}></button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services_ar;
// http://localhost:3000/financialservices/aas?pagenumb=2