// GoogleAnalytics.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = ({ trackingID }) => {
  const location = useLocation();

  useEffect(() => {
    // Define dataLayer as a global variable
    window.dataLayer = window.dataLayer || [];

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingID}`;
    script.async = true;
    document.head.appendChild(script);

    const initializeGoogleAnalytics = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', trackingID);
    };

    script.addEventListener('load', initializeGoogleAnalytics);

    return () => {
      script.removeEventListener('load', initializeGoogleAnalytics);
    };
  }, [trackingID]);

  useEffect(() => {
    const unregister = listenToRouteChanges();
    return () => unregister();
  }, []);

  const listenToRouteChanges = () => {
    const handleRouteChange = () => {
      window.gtag('config', trackingID, {
        page_path: window.location.pathname,
      });
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  };

  return null;
};

export default GoogleAnalytics;
