import React from 'react'
import styles from './Landing.module.css'
function Generalservicesmob() {
    return (
        // ms-2 ms-md-5 ml-2 ml-md-5
        <div className={`${styles.section} ms-md-5 ms-3`}>
            <div className="row row-cols-md-3 row-cols-sm-2">
                <div className="col-xxl-4 col-md-6 col-sm-12" style={{ alignSelf: 'center' }}>
                    <div className={styles.textContent}>

                        <span className={styles.mainTitleWhite}>General </span>
                        <span className={styles.mainTitleYellow}>Services</span>
                    </div>
                </div>
                <div className="col-6 col-md-6 mb-3">
                    <a style={{ textDecoration: `none`, color: `inherit` }} href="/generalservices/servicestypes?pagenumb=1">
                        <div className={` ${styles.serBox}`}>

                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61" viewBox="0 0 61 61" fill="none">
                                    <g clip-path="url(#clip0_6335_657)">
                                        <path d="M13 43C13 44.3261 13.5268 45.5979 14.4645 46.5355C15.4021 47.4732 16.6739 48 18 48C19.3261 48 20.5979 47.4732 21.5355 46.5355C22.4732 45.5979 23 44.3261 23 43C23 41.6739 22.4732 40.4021 21.5355 39.4645C20.5979 38.5268 19.3261 38 18 38C16.6739 38 15.4021 38.5268 14.4645 39.4645C13.5268 40.4021 13 41.6739 13 43Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M38 43C38 44.3261 38.5268 45.5979 39.4645 46.5355C40.4021 47.4732 41.6739 48 43 48C44.3261 48 45.5979 47.4732 46.5355 46.5355C47.4732 45.5979 48 44.3261 48 43C48 41.6739 47.4732 40.4021 46.5355 39.4645C45.5979 38.5268 44.3261 38 43 38C41.6739 38 40.4021 38.5268 39.4645 39.4645C38.5268 40.4021 38 41.6739 38 43Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 43H8V28M8 28L13 15.5H35.5L45.5 28M8 28H45.5M45.5 28H48C49.3261 28 50.5979 28.5268 51.5355 29.4645C52.4732 30.4021 53 31.6739 53 33V43H48M38 43H23M30.5 28V15.5" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6335_657">
                                            <rect width="60" height="60" fill="white" transform="translate(0.5 0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div>
                                <h5>Car Services </h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-6 col-md-6 mb-3">
                    <a style={{ textDecoration: `none`, color: `inherit` }} href="/generalservices/servicestypes?pagenumb=1">
                        <div className={` ${styles.serBox}`} >
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                                    <g clip-path="url(#clip0_6335_801)">
                                        <path d="M13 30H8L30.5 7.5L53 30H48" stroke="#DDB96E" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M13 30V47.5C13 48.8261 13.5268 50.0979 14.4645 51.0355C15.4021 51.9732 16.6739 52.5 18 52.5H43C44.3261 52.5 45.5978 51.9732 46.5355 51.0355C47.4732 50.0979 48 48.8261 48 47.5V30" stroke="#DDB96E" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M25.5 30H35.5V40H25.5V30Z" stroke="#DDB96E" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6335_801">
                                            <rect width="60" height="60" fill="white" transform="translate(0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div>
                                <h5>Home Service</h5>
                            </div>
                        </div>
                    </a>
                </div>



                <div className="col-6 col-md-6 mb-3">
                    <a style={{ textDecoration: `none`, color: `inherit` }} href="/generalservices/servicestypes?pagenumb=1">
                        <div className={` ${styles.serBox}`} >
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                                    <g clip-path="url(#clip0_6335_825)">
                                        <path d="M36.25 16.2501L43.75 9.00006C44.7114 8.03865 46.0154 7.49854 47.375 7.49854C48.7346 7.49854 50.0386 8.03865 51 9.00006C51.9614 9.96147 52.5015 11.2654 52.5015 12.6251C52.5015 13.9847 51.9614 15.2886 51 16.2501L43.75 23.7501L50 42.5001L43.75 48.8751L35 32.5001L27.5 40.0001V47.5001L22.5 52.5001L18.75 41.2501L7.5 37.5001L12.5 32.5001H20L27.5 25.0001L11.25 16.2501L17.5 10.0001L36.25 16.2501Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6335_825">
                                            <rect width="60" height="60" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div>
                                <h5>
                                    Travel Services
                                </h5>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="col-6 col-md-6 mb-3">
                    <a style={{ textDecoration: `none`, color: `inherit` }} href="/generalservices/servicestypes?pagenumb=1">
                        <div className={` ${styles.serBox}`} >

                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                                    <g clip-path="url(#clip0_6335_2885)">
                                        <path d="M33 22.5C33 21.837 33.2634 21.2011 33.7322 20.7322C34.2011 20.2634 34.837 20 35.5 20H50.5C51.163 20 51.7989 20.2634 52.2678 20.7322C52.7366 21.2011 53 21.837 53 22.5V47.5C53 48.163 52.7366 48.7989 52.2678 49.2678C51.7989 49.7366 51.163 50 50.5 50H35.5C34.837 50 34.2011 49.7366 33.7322 49.2678C33.2634 48.7989 33 48.163 33 47.5V22.5Z" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M45.5 20V12.5C45.5 11.837 45.2366 11.2011 44.7678 10.7322C44.2989 10.2634 43.663 10 43 10H10.5C9.83696 10 9.20107 10.2634 8.73223 10.7322C8.26339 11.2011 8 11.837 8 12.5V42.5C8 43.163 8.26339 43.7989 8.73223 44.2678C9.20107 44.7366 9.83696 45 10.5 45H33" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M40.5 22.5H45.5" stroke="#FEFCFA" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_6335_2885">
                                            <rect width="60" height="60" fill="white" transform="translate(0.5)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div>
                                <h5>
                                    Technology Services
                                </h5>
                            </div>
                        </div>
                    </a>
                </div>


            </div>
        </div >
    )
}

export default Generalservicesmob